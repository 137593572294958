

/* Hero custom button */

.ath-button{
   background-color: #fbc43a;
   border-color: #fbc43a;
   color: #082e67;
 }
 
 .ath-button:hover{
   background-color: #082e67;
   border-color:#082e67;
   color: #fff;
 }


 
 /* Hero Text */

 .ath-hero-text{
  color: #082e67;
  font-weight:bold;
  font-size: 3rem;
 }

 .ath-custom-margin{
  margin-top: 7rem;
  margin-bottom: 13rem;
 }