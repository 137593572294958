.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }

  .b-example-divider {
    width: 100%;
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }

  .b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
  }

  .bi {
    vertical-align: -.125em;
    fill: currentColor;
  }

  .nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
  }

  .nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .btn-bd-primary {
    --bd-violet-bg: #712cf9;
    --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-white);
    --bs-btn-bg: var(--bd-violet-bg);
    --bs-btn-border-color: var(--bd-violet-bg);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #6528e0;
    --bs-btn-hover-border-color: #6528e0;
    --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #5a23c8;
    --bs-btn-active-border-color: #5a23c8;
  }

  .bd-mode-toggle {
    z-index: 1500;
  }

  .bd-mode-toggle .dropdown-menu .active .bi {
    display: block !important;
  }


  .bi {
    display: inline-block;
    width: 1rem;
    height: 1rem;
  }
  
  /*
   * Sidebar
   */
  
  @media (min-width: 768px) {
    .sidebar .offcanvas-lg {
      position: -webkit-sticky;
      position: sticky;
      top: 48px;
    }
    .navbar-search {
      display: block;
    }
  }
  
  .sidebar .nav-link {
    font-size: .875rem;
    font-weight: 500;
  }
  
  .sidebar .nav-link.active {
    color: #2470dc;
  }
  
  .sidebar-heading {
    font-size: .75rem;
  }
  
  /*
   * Navbar
   */
  
  .navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  }
  
  .navbar .form-control {
    padding: .75rem 1rem;
  }


/* COMMNET STYLE */

.comment-button{
  background-color: #082e67 !important;
  border-color: #082e67 !important;
  color: #fff !important;
}

.comment-button:hover {
  background-color: #fbc43a !important;
  border-color: #fbc43a !important;
  color: #082e67 !important;
}

.comment-list{

  border: 1px solid #082e67 !important;

}

.comment-list:hover{
  border: 1px solid #fbc43a !important;
}


/* Product style */

.card-border-product{
  border: 1px solid #082e67 !important;
}

.card-border-product:hover{
  border: 1px solid #fbc43a !important;
}


.topbar-button-ath-color{
  background-color: #ffffff !important;
  border-color: #082e67 !important;
  color: #082e67 !important;
  border-radius: 5px;
 }

 .topbar-button-ath-color:hover{
  background-color: #fbc43a!important;
  border-color: #fbc43a !important;
  color: #ffffff  !important;
  border-radius: 5px;
 }

 .cancel-button-ath-color{
  background-color: #ff4d4f!important;
  border-color: #ff4d4f !important;
  color: #fff !important;
  border-radius: 5px;
 }

 .cancel-button-ath-color:hover{
  background-color: #ffff!important;
  border-color: #fbc43a!important;
  color: #ff4d4f !important;
  border-radius: 5px;
 }

 .save-button-ath-color{
  background-color: #fbc43a !important;
  border-color: #fbc43a !important;
  color: #ffffff ;
  border-radius: 5px;
 }

 .save-button-ath-color:hover{
  background-color: #ffffff !important;
  border-color: #fbc43a !important;
  color: #082e67  !important;
  border-radius: 5px;
 }


 .flow-container {
  width: 100%;
  height: 100%; /* Set an appropriate height */
}
