.italic-text {
    font-style: italic;
    font-size: 20px;
    color: #010307;
  }
  

  .ath-custom-margin{
    margin-top: 6rem;
    margin-bottom: 10rem;
   }


   .ath-feature-text{
    color: #082e67;
    font-weight:bold;
    font-size: 3rem;
   }

   .ath-feature-card-bg{
    background-color: #fbc43a;
   }
  