navbar-bg {
   background-color: #082E67 !important;
   color: black;
}

/* auth buttons */


.ath-auth-button{
   background-color: #082e67;
   border-color: #082e67;
   color: #fff;
 }
 
 .ath-auth-button:hover{
   background-color: #fbc43a;
   border-color:#fbc43a;
   color: #082e67;
 }


 .ath-auth-button-2{
   background-color: #fff;
   border-color: #fbc43a;
   color: #082e67;
 }

 .ath-auth-button-2:hover{
   background-color: #fbc43a;
   border-color: #fbc43a;
   color: #082e67;
 }

/* Custom styles for antd Menu */
/* .ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-menu {
  border: none!important; 
} */
