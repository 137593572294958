
/* Newsletter */

.newsletter-button{
   background-color: #082e67;
   border-color: #082e67;
   color: #fff;
 }
 
 .newsletter-button:hover{
   background-color: #082e67;
   border-color: #082e67;
   color: #fff;
 }
 
 .bg-newsletter{
   background-color: #fbc43a;
 }

 .newsletter-text{
  color: #082e67;
  font-weight:300;
  font-size: 2rem;
 }


 .newsletter-text2{
  color: #082e67;
  font-weight:300;
  font-size: 1rem;
 }

 .bottom-footer-text{
  color: #082e67;
  font-weight:semi-bold;
  font-size: 1rem;
 }