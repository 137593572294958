.container {
  padding: 20px;
}

.comment-list .ant-list-item {
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.comment-list .ant-list-item-meta {
  margin-bottom: 10px;
}

.comment-list .ant-avatar {
  background-color: #87d068;
}

.comment-list p {
  margin: 0;
}

.comment-list small {
  display: block;
  margin-top: 10px;
  color: #888;
}

.ath-custom-margin {
  margin-top: 6rem;
  margin-bottom: 20rem;
}

.ath-review-text {
  color: #082e67;
  font-weight: bold;
  font-size: 3rem;
}

.ath-review-card-bg {
  background-color: #fbc43a;
}

/* Add color to arrows */
.custom-carousel .slick-prev,
.custom-carousel .slick-next {
  color: #082e67 !important; /* Set your desired arrow color */
}

/* Add space between cards */
.ath-review-card {
  padding: 10px;
  margin-right: 5px;
  margin-top: 16px;
  margin-bottom: 25px;
}

/* Media queries for responsive design */
@media (max-width: 576px) {
  .ath-review-card {
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .custom-carousel .slick-prev,
  .custom-carousel .slick-next {
    display: none; /* Hide arrows on very small screens */
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .ath-review-card {
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .ath-review-card {
    margin-right: 10px;
    margin-top: 12px;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .ath-review-card {
    margin-right: 15px!important;
    margin-top: 16px !important;
    margin-bottom: 25px !important;
  }
}
