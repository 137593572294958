/* AddCallModal.css */

/* .form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.form-col {
  width: 48%;
} */

/* Add more custom styling as needed */
 

/* Example CSS */
.form-col-reg-co{
  width: 100% !important;
  /* height: 50% !important; */
  /* padding-top: 4rem !important;
  padding-bottom: 4rem !important; */
  /* Add any other styles you need */
}


.cancel-button-ath-color{
  background-color: #ff4d4f!important;
  border-color: #ff4d4f !important;
  color: #fff !important;
  border-radius: 5px;
 }

 .cancel-button-ath-color:hover{
  background-color: #ffff!important;
  border-color: #fbc43a!important;
  color: #ff4d4f !important;
  border-radius: 5px;
 }

 .save-button-ath-color{
  background-color: #fbc43a !important;
  border-color: #fbc43a !important;
  color: #ffffff ;
  border-radius: 5px;
 }

 .save-button-ath-color:hover{
  background-color: #ffffff !important;
  border-color: #fbc43a !important;
  color: #082e67  !important;
  border-radius: 5px;
 }