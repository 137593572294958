/* ATH Auth css styles */


.ath-header-text{
   color: #082e67;
   font-weight:bold;
   font-size: 3rem;
  }

 
  .ath-desc-text{
   color: #000;
   font-weight:semi-bold;
   font-size: 1rem;
  }


  .ath-auth-button{
   background-color: #082e67 !important;
   border-color: #082e67 !important;
   color: #fff !important;
 }
 
 .ath-auth-button:hover{
   background-color: #fbc43a !important;
   border-color:#fbc43a !important;
   color: #082e67 !important;
 }