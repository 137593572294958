/* src/components/fixedreviewbox.css */

.fixed-review-box {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px;
  background-color: white;
  box-shadow: 0 4px 8px #fbc43a;
  border-radius: 14px;
  z-index: 9999;
  background: #fff;
  /* border: 1px solid #fbc43a; */
  border-top: 1px solid #fbc43a;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.fixed-review-box .ant-form-item {
  margin-bottom: 10px;
}

.fixed-review-box .ant-btn {
  width: 100%;
}

.ath-review-button {
  background-color: #082e67 !important;
  border-color: #082e67 !important;
  color: #fff !important;
  border-radius: 14px;
}

.ath-review-button:hover {
  /* background-color: #fbc43a !important;
  border-color: #fbc43a !important; */
  color: #082e67 !important;
  border-radius: 14px;
}

.fixed-review-box-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  z-index: 1000;
}

.toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background: #082e67;
  border: 1px solid #082e67;
  border-bottom: 1px solid #082e67; */
  padding: 5px;
  cursor: pointer;
  font-size: 26px;
  color:  #082e67;
  z-index: 1000;
}

.toggle-button:hover {
  /* background: #fbc43a; */
  color: #fbc43a;
  /* border: 1px solid #fbc43a; */
}
