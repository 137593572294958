.drawer-display {
   z-index: 2000 !important;
 }


/* Footer button spacing */
.drawer-footer-buttons {
   display: flex;
   justify-content: space-between;
 }
 
 /* Center form content and make input take full height */
 .form-centered {
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .form-container {
   height: 100%;
 }
 
 .form-item-centered {
   display: flex;
   flex-direction: column;
   align-items: center;
 }
 
 .form-label {
   font-size: 16px; 
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
 }
 
 .form-input {
   width: 100%;
   height: 50px; 
   font-size: 16px;
   padding: 10px;
 }
 
 .topbar-button-ath-color{
  background-color: #ffffff !important;
  border-color: #082e67 !important;
  color: #082e67 !important;
  border-radius: 5px;
 }

 .topbar-button-ath-color:hover{
  background-color: #ffffff !important;
  border-color: #fbc43a !important;
  color: #082e67 !important;
  border-radius: 5px;
 }

 .cancel-button-ath-color{
  background-color: #ff4d4f!important;
  border-color: #ff4d4f !important;
  color: #fff !important;
  border-radius: 5px;
 }

 .cancel-button-ath-color:hover{
  background-color: #ffff!important;
  border-color: #fbc43a!important;
  color: #ff4d4f !important;
  border-radius: 5px;
 }

 .save-button-ath-color{
  background-color: #fbc43a!important;
  border-color: #fbc43a !important;
  color: #ffffff  !important;
  border-radius: 5px;
 }

 .save-button-ath-color:hover{
  background-color: #ffffff!important;
  border-color: #fbc43a !important;
  color: #082e67  !important;
  border-radius: 5px;
 }