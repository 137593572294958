/* Header.css */

.custom-header {
  height: 80px; /* Adjust the height value as needed */
}

.cursor-hand {
  cursor: pointer;
}


/* Mobile responsiveness */
@media (max-width: 767px) {
  .navbar {
    padding: 10px;
  }
} 

/* Tablet responsiveness */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar {
    padding: 15px;
  }
}

.navbar {
  z-index: 1000; /* Set a lower z-index */
}

/* Blur background */
.blur-background {
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(2px);
  z-index: 1;
}

/* Blur cover */
.blur-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(9px);
  opacity: 9;
  z-index: 2;
}

.coming-soon-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 170px;
  margin-bottom: 170px;
  transform: translate(-50%, -50%);
  z-index: 3;
  color: black;
  font-size: 24px;
}

.cancel-button-ath-color{
  background-color: #ff4d4f!important;
  border-color: #ff4d4f !important;
  color: #fff !important;
  border-radius: 5px;
 }

 .cancel-button-ath-color:hover{
  background-color: #ffff!important;
  border-color: #fbc43a!important;
  color: #ff4d4f !important;
  border-radius: 5px;
 }

 .save-button-ath-color{
  background-color: #fbc43a !important;
  border-color: #fbc43a !important;
  color: #ffffff ;
  border-radius: 5px;
 }

 .save-button-ath-color:hover{
  background-color: #ffffff !important;
  border-color: #fbc43a !important;
  color: #082e67  !important;
  border-radius: 5px;
 }